import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)
const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import('@/views/index/index'),
    meta: {
      title: '首页'
    }
  },
  {
    path: '/complaint/create',
    name: 'complaintCreate',
    component: () => import('@/views/complaint/create'),
    meta: {
      title: '新建举报'
    }
  },
  {
    path: '/complaint/codelogin',
    name: 'complaintCodelogin',
    component: () => import('@/views/complaint/codelogin'),
    meta: {
      title: '我的举报'
    }
  },
  {
    path: '/complaint/list',
    name: 'complaintList',
    component: () => import('@/views/complaint/list'),
    meta: {
      title: '我的举报列表'
    }
  },
  {
    path: '/notice/index',
    name: 'noticeIndex',
    component: () => import('@/views/notice/index'),
    meta: {
      title: '用户须知'
    }
  },
  {
    path: '/complaint/result',
    name: 'complaintResult',
    component: () => import('@/views/complaint/result'),
    meta: {
      title: '举报成功'
    }
  }
]
const router = new Router({
  h5: {
    vueRouterDev: true
  },
  routes
})
export default router
