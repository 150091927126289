import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vant from 'vant'
//  引入vant样式
import 'vant/lib/index.css'
//  引入通用样式
import '@/assets/css/base.css'
//  引入字体比例控制
import '@/assets/js/font.js'
import VueMeta from 'vue-meta'
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
})
Vue.use(Vant)
Vue.config.productionTip = false
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
